@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@layer base { 
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}
.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}
.content-div:hover {
    background-image: linear-gradient(
        to right,
        hsla(165, 74%, 61%, 0.8),
        rgba(121, 112, 255, 0.8)
    )!important;
}

.hdr-img {
    animation: hdr-logo-spin 10s linear infinite;
    animation-direction: alternate;
  }
@keyframes hdr-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(45deg);
    }
  }
  
  @keyframes hdr-logo-spin-reverse {
    from {
      transform: rotate(-10deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .h-div {
    background-image: linear-gradient(
        to right,
        rgba(121, 112, 255, 0.8),
        hsla(165, 74%, 61%, 0.8)
    )!important;
}

.h-div:hover {
  background-image: linear-gradient(
      to right,
      hsla(165, 74%, 61%, 0.8),
      rgba(121, 112, 255, 0.8)
  )!important;
}


  .p-div:hover {
    color: black;
    background-image: linear-gradient(
        to right,
        rgba(121, 112, 255, 0.8),
        hsla(165, 74%, 61%, 0.8)
    )!important;
}